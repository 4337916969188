.profile-summury-container .dark-grey {
    color: rgb(105, 101, 101);
}

.profile-summury-container .light-grey {
    color: rgba(105, 101, 101, 0.558);
}

.profile-summury-container .username-font-size {
    font-size: 40px;
}

.profile-summury-container .profile-summury-font-size {
    font-size: 12px;
}

.profile-summury-container .profile-details {
    display: flex;
    justify-content: space-between;
}

.profile-summury-container .profile-bio {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    flex: 2;
}

.profile-summury-container .view-report-container {
    flex: 1;
    text-align: end;
}

.profile-summury-container .sky-blue-color {
    color: rgb(128, 159, 237);
}

.profile-summury-container .profile-bio .profile-pic img {
    width: 180px;
    height: 180px;
    border-radius: 10px;
    object-fit: cover;
}

.profile-summury-container .content-alignment-content {
    /* height: 70px;
    display: flex;
    flex-wrap: wrap;
    gap: 0px 50px; */
}

.profile-summury-container .content-alignment-content .progress-container {
    width: 325px;
    height: 100%;
}

.profile-summury-container .content-alignment-heading {
    /* border: 1px solid red; */
    display: inline-block;
    padding: 10px 0px;
    margin: 0px;
    margin-bottom: -20px;
}

.profile-summury-container .profile-summury {
    display: flex;
    flex-direction: column;
    gap: 5px 0px;
}

.profile-summury-container .progress-container {
    height: 80px;
}

.profile-summury-container .content-title {
    margin-bottom: 3px;
}

.profile-summury-container .view-report {
    text-decoration: underline;
    word-wrap: break-word;
}

#root > div > div > main > div > div > div > div.add-influencer-wrapper > div > div > form > div > div > div > div {
    /* height: 43px; */
}



.add_influencer_header {
    font-family: Nunito Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.button-conteiner {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.bulk-title {
    color: #6D6D6D;
    font-family: Nunito Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.profile-summury-container .progress {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 1rem;
    overflow: hidden;
    font-size: .75rem;
    background-color: #E9ECEF;
    border-radius: 0.25rem;
    height: 10px;
}

.profile-summury-container .progress-bar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    color: #fff;
    text-align: center;
    /* background-color: #007BFF; */
    transition: width .6s ease;
    background: linear-gradient(to right, #34F5F3 0%, rgb(128, 159, 237) 100%);
    border-radius: 20px;
}

.profile-summury-container .progress-amount {
    display: flex;
    justify-content: space-between;
    gap: 0px 10px;
}

/* media Query */
@media(max-width:600) {
    .profile-summury-container .profile-bio .profile-pic img {
        width: 50px;
        height: 50px;
        border-radius: 10px;
        object-fit: cover;
    }

    .view-report {
        font-size: 5px;
    }
}

.add-influencer {
    font-weight: 700;
    font-size: 1.5rem;
    background: none;
    color: inherit;
    border: none;
    cursor: pointer;
}

.add-influencer-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400;
    border: 2px solid #000;
    width: 50%;
    background: #fff;
    padding: 10px;
}

.add-influencer-wrapper {
    display: flex;
    flex-direction: row;
}

@media (max-width: 800px) {
    .add-influencer-wrapper {
        display: flex;
        flex-direction: column;
    }
}


.textLabel {
    /* margin: auto; */
    padding: 10px;
    /* width: 190px; */
    display: flex;
    color: #6D6D6D;

font-family: Nunito Sans;
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: normal;
}

.parent_box {
    display: flex;
    flex-direction: column;
}


.textLabel svg {
    margin-right: 10px;
}

.add-influencer-menu {
    width: 100%;
}
.upload_influencer_modal{
    background-color: unset !important;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 20px;
    max-width: 480px;
    margin: auto;
}

.social-networks {
    color: #6D6D6D;
    font-family: Nunito Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.errorMessageForBulkUpload{
    overflow: auto;
    max-height: 448px;
}
.stepbox{
    color: #0CA5EA;
    font-size: 18px;
    font-weight: 700;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin: 16px 0 16px 0;
}